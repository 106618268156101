
// @ is an alias to /sr
import { defineComponent, onBeforeMount, reactive, ref, toRefs } from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import LoadMore from "@/components/atomics/LoadMore.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Popup from "@/components/popups/Popup.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import Tooltip from "@/components/atomics/ToolTip.vue";
import { GroupRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Group",
  components: {
    AfterLoginPageLayout,
    FlatButton,
    TextButton,
    ValidationTextField,
    Popup,
    MenuOptionPopup,
    Tooltip,
    LoadMore,
  },
  setup() {
    const state = reactive({
      showTooltipTemplate: false,
      showTooltipMember: false,
      showTooltipFolder: false,
      isShowDeletePopupIndex: -1,
      isShowEditPopupIndex: -1,
      groupPermissions: {
        role: 0,
        canCreate: false,
        bookmark: 0
      },
    });

    const groupEditState = reactive({
      id: "",
      groupName: "",
      description: "",
    });

    const searchKeyword = ref("");
    const searchKeywordTextField = ref("");
    let delay: ReturnType<typeof setTimeout>;

    let page = 1;
    let total = ref(0);

    const groups = ref();

    const { getGroupList, deleteGroup, updateGroup } = groupMethods();

    const store = useStore();
    const { t } = useI18n();

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };
    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const hideEditPopup = () => {
      groupEditState.id = "";
      groupEditState.groupName = "";
      groupEditState.description = "";
      state.isShowEditPopupIndex = -1;
    };

    const { editGroup } = formHandler(groupEditState, updateGroup, () => {
      groups.value[state.isShowEditPopupIndex] = {
        ...groups.value[state.isShowEditPopupIndex],
        name: groupEditState.groupName,
        description: groupEditState.description,
      };

      hideEditPopup();
    });

    const removeGroup = async (group: any) => {
      await deleteGroup(group.id)
        .then((response) => {
          if (response) {
            groups.value.splice(groups.value.indexOf(group), 1);
            total.value = total.value - 1;
            showSuccessNotification(t("notification.deleteSuccess"));
          }
          state.isShowDeletePopupIndex = -1;
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const PAGE_SIZE_DEFAULT = 10;

    const loadMoreGroups = async (onScroll: boolean) => {
      const currentPage = Math.ceil(groups.value.length / PAGE_SIZE_DEFAULT);
      const isNextPage = groups.value.length % PAGE_SIZE_DEFAULT === 0;
      if (isNextPage) page = currentPage + 1;
      else page = currentPage;

      const moreGroups = await getGroupList(
        page,
        PAGE_SIZE_DEFAULT,
        searchKeyword.value,
        state.groupPermissions.bookmark as any
      );

      if (moreGroups.pagination.totalRecords > total.value) {
          total.value = moreGroups.pagination.totalRecords;
        } else if (
          moreGroups.data.length % PAGE_SIZE_DEFAULT !== 0 &&
          groups.value.length >= moreGroups.pagination.totalRecords && !onScroll
        )
          showErrorNotification(t("errors.errorNoNewRecord"));
        for (const index in moreGroups.data) {
          const isContain = groups.value.some(
            (member: any) => member.id === moreGroups.data[index].id
          );
          if (!isContain) groups.value.push(moreGroups.data[index]);
        }

        state.groupPermissions.bookmark = moreGroups.pagination.bookmark || 0;


    };

    const search = (keyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        searchKeyword.value = keyword;
        const payload = await getGroupList(
          (page = 1),
          PAGE_SIZE_DEFAULT,
          searchKeyword.value
        );
        groups.value = payload.data;
        total.value = payload.pagination.totalRecords ?? 0;
        state.groupPermissions.bookmark = payload.pagination.bookmark || 0;
      }, 300);
    };

    const clearSearch = () => {
      if (searchKeyword.value != "") {
        searchKeyword.value = "";
        searchKeywordTextField.value = "";
        search("");
      }
    };

    onBeforeMount(async () => {
      const payload = await getGroupList();
      groups.value = payload.data;
      total.value = payload.pagination.totalRecords ?? 0;
      state.groupPermissions = payload.pagination;
    });

    // onMounted(() => {
    //   const scrollElement = document.querySelector(".left-part > .bottom-part");
    //   // scrollElement?.addEventListener("scroll", () =>
    //   //   loadMoreGroups(scrollElement)
    //   // );
    // });

    return {
      ...toRefs(state),
      groups,
      removeGroup,
      ...toRefs(groupEditState),
      editGroup,
      hideEditPopup,
      searchKeyword,
      searchKeywordTextField,
      search,
      total,
      loadMoreGroups,
      clearSearch,
    };
  },
  methods: {
    showEditPopup(index: number, group: any) {
      this.isShowEditPopupIndex = index;
      this.groupName = group.name;
      this.description = group.description;
      this.id = group.id;
    },
    navigationAddGroup() {
      this.$router.push("/group/group-add");
    },
    navigationFolderGroup(group: any) {
      this.$router.push({
        name: "GroupFolder",
        query: {
          groupID: group.id,
          groupName: group.name,
        },
      });
    },
    navigationMemberGroup(group: any) {
      this.$router.push({
        name: "GroupMember",
        query: {
          groupID: group.id,
          groupName: group.name,
        },
      });
    },
    navigationTemplateGroup(group: any) {
      this.$router.push({
        name: "GroupTemplate",
        query: {
          groupID: group.id,
          groupName: group.name,
        },
      });
    },
    navigateToGroupDetail(group: any) {
      this.$router.push({
        name: "GroupDetails",
        query: {
          groupID: group.id,
          groupName: group.name
        }
      })
    }

  },
});

const formHandler = (
  group: any,
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const { values, errors, submitForm, setErrors } = useForm();
  const store = useStore();
  const { t } = useI18n();

  const showSuccessNotification = (text: string) => {
    store.commit("notification/showSuccessNotification", text);
  };

  const editGroup = () => {
    submitForm()
      .then(async () => {
        if (errors.value["groupName"]) return;

        const data = {
          id: group.id,
          name: values["groupName"],
          description: values["description"],
        };

        if (await onSubmit(data)) {
          onSuccess();
        }
        showSuccessNotification(t("notification.updateSuccess"));
      })
      .catch((e) => {
        setErrors({
          groupName: e.data.message,
        });
      });
  };

  return {
    editGroup,
  };
};

const groupMethods = () => {
  const { getGroupList, deleteGroup, updateGroup } =
    RepositoryFactory.getRepository<GroupRepository>(GroupRepository);

  return {
    getGroupList,
    deleteGroup,
    updateGroup,
  };
};
